// -------------------------------------------------
// Mixins
// -------------------------------------------------

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}

// Border Radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
}
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}

// Transitions
@mixin transition($transition) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
     -moz-transition-delay: $transition-delay;
       -o-transition-delay: $transition-delay;
          transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration) {
  -webkit-transition-duration: $transition-duration;
     -moz-transition-duration: $transition-duration;
       -o-transition-duration: $transition-duration;
          transition-duration: $transition-duration;
}



// Add an alphatransparency value to any background or border color (via Elyse Holladay)
@mixin translucent-background($color: $white, $alpha: 1) {
  background-color: hsla(hue($color), saturation($color), lightness($color), $alpha);
}
@mixin translucent-border($color: $white, $alpha: 1) {
  border-color: hsla(hue($color), saturation($color), lightness($color), $alpha);
  @include background-clip(padding-box);
}


// Gradient Bar Colors for buttons and alerts
@mixin gradientBar($primaryColor, $secondaryColor, $textColor: #fff, $textShadow: 0 -1px 0 rgba(0,0,0,.25)) {
  color: $textColor;
  text-shadow: $textShadow;
  @include gradient-vertical($primaryColor, $secondaryColor);
  border-color: $secondaryColor $secondaryColor darken($secondaryColor, 15%);
  border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) fadein(rgba(0,0,0,.1), 15%);
}
