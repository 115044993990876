// -------------------------------------------------
// Site Specific Variables
// -------------------------------------------------

// Example, group similar variables, only use variables for common colours & values
// $all-lowercase: value;

$font-primary: 'Jura', Helvetica, Arial, sans-serif;
$font-secondary: 'Jura', Helvetica, Arial, sans-serif;
$color-primary: #0c4870;
$color-secondary: #007dab;

$font-size-x-large: 62px;
$font-size-large: 30px;
$font-size-medium: 26px;
$font-size-sm-medium: 24px;
$font-size-small: 18px;

$line-height: 1.55;

h1, h2, h3, h4, h5, h6{
	font-family: $font-primary;
}

p{
	line-height: $line-height;
	color: #333;
	font-size: 400;
	font-family: $font-primary;
}

h1{
	font-size: $font-size-x-large;
}

h2{
	font-size: $font-size-large;
	margin-bottom: 65px;
}

h3{
	font-size: $font-size-sm-medium;
}

h4{
	font-size: $font-size-small;
}
