@font-face {
	font-family: "ab-icons";
	src: url('./../fonts/ab-icons.eot');
	src: url('./../fonts/ab-icons.eot?#iefix') format('eot'),
		url('./../fonts/ab-icons.woff2') format('woff2'),
		url('./../fonts/ab-icons.woff') format('woff'),
		url('./../fonts/ab-icons.ttf') format('truetype'),
		url('./../fonts/ab-icons.svg#ab-icons') format('svg');
}

@mixin icon-styles {
	font-family: "ab-icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
	line-height: 0;
}

%icon {
	@include icon-styles;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == bars {
		$char: "\E001";
	}
	@if $filename == email-solid {
		$char: "\E002";
	}
	@if $filename == email {
		$char: "\E003";
	}
	@if $filename == facebook {
		$char: "\E004";
	}
	@if $filename == gps {
		$char: "\E005";
	}
	@if $filename == insulation {
		$char: "\E006";
	}
	@if $filename == lagging {
		$char: "\E007";
	}
	@if $filename == maintenance {
		$char: "\E008";
	}
	@if $filename == maintenance2 {
		$char: "\E009";
	}
	@if $filename == phone {
		$char: "\E00A";
	}
	@if $filename == pipe {
		$char: "\E00B";
	}
	@if $filename == scaffolding {
		$char: "\E00C";
	}
	@if $filename == settings {
		$char: "\E00D";
	}
	@if $filename == times {
		$char: "\E00E";
	}
	@if $filename == up-arrow {
		$char: "\E00F";
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %icon;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}

.icon-bars {
	@include icon(bars);
}
.icon-email-solid {
	@include icon(email-solid);
}
.icon-email {
	@include icon(email);
}
.icon-facebook {
	@include icon(facebook);
}
.icon-gps {
	@include icon(gps);
}
.icon-insulation {
	@include icon(insulation);
}
.icon-lagging {
	@include icon(lagging);
}
.icon-maintenance {
	@include icon(maintenance);
}
.icon-maintenance2 {
	@include icon(maintenance2);
}
.icon-phone {
	@include icon(phone);
}
.icon-pipe {
	@include icon(pipe);
}
.icon-scaffolding {
	@include icon(scaffolding);
}
.icon-settings {
	@include icon(settings);
}
.icon-times {
	@include icon(times);
}
.icon-up-arrow {
	@include icon(up-arrow);
}
