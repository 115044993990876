@import '_variables';
@import '_mixins';
@import "_ab-icons";

/* ==================================================
  General
  ================================================== */
a {transition: 0.5s; }
.pull-left {float: left; }
.pull-right {float: right; }

/* ==================================================
  Elements
  ================================================== */

.btn {
    background-color: $color-primary;
    font-family: $font-secondary;
    font-weight: 800;
    color: #fff;
    padding: 15px 45px;
    border-radius: 50px;
    &:hover {
        background-color: $color-secondary;
        color: #fff;
    }
    &:focus {
      color: #fff;
    }

}
.btn-ghost {
    border: 3px solid #fff;
    background-color: transparent;
    &:hover {
        background-color: #fff;
        color: $color-primary;
    }
}
section {
    padding: 85px 0;
}
.card {
    position: relative;
    padding: 0;
    border: 0;
    border-radius: 0;
    margin: 0;
    overflow: hidden;
}
a:hover {
    text-decoration: none !important;
}
.scrolltop {
    display: none;
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: $color-primary;
    color: #fff;
    text-align: center;
    font-size: 20px;
    padding-top: 6px;
    border: 1px solid white;
    &:hover, &:active, &:focus {
        color: #fff !important;
        opacity: .75;
    }
}

/* ==================================================
  Hero Styling
================================================== */

.hero {
    display: table;
    position: relative;
    background: url(../img/cover.jpg) top center;
    background-size: cover;
    padding: 40px 0 50px 0;
    color: #fff;
    width: 100%;
    height: 100vh;
    @media only screen and (max-height: 900px ) {
      padding: 74px 0 150px 0;
    }
    @media only screen and (max-height: 480px ) {

    }
    &:after {
        content: '';
        z-index: 0;
        position: absolute;
        // background: rgba(0, 0, 0, .45);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .container {
        position: relative;
        z-index: 1;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        .btn.btn-full {
          background-color: #14a4fe;
          box-shadow: 0 0 16px #000;
        }
        h1 {
          text-shadow: 0 0 18px #000;
        }
    }
}

.hero-brand {
    margin-bottom: 75px;
    display: inline-block;
    &:hover {
        opacity: .75;
    }
}

.tagline {
    font-family: $font-primary;
    font-size: $font-size-medium;
    margin: 45px 0 75px 0;
    color: #fff;
    text-shadow: 0 0 18px #000;
}

/* ==================================================
  Header Styling
================================================== */
#header {
  // background: $color-primary;
  background: rgb(0,125,171);
  background: linear-gradient(-90deg, rgba(0,125,171,1) 39%, rgba(12,72,112,1) 100%);
  height: 70px;
  
  #logo {
    margin: 14px 25px 0 0; 

    h1 {
      font-size: 36px;
      margin: 0;
      padding: 2px 0;
      line-height: 1;
      font-family: $font-primary;
      font-weight: 700;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
    
    h1 a, h1 a:hover {
      color: #fff;
    }
    
    img {
      padding: 0;
      margin: 0;
      max-height: 40px;
    }
  }
  @media (max-width: 768px) {
    height: 50px;
    #logo {
      margin: 10px 0 0 0; 
      h1 {
        padding: 2px 0;
        font-size: 26px;
      }
      
      img { 
        max-height: 30px;
      }
    }
  }
  
  .social-nav {
    margin-top: 15px;
    a {
      font-size: 24px;
      margin-left: 15px;
      color: #fff;
      &:hover {
        color: rgba(255, 255, 255, .75);
      }
    }
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/* Nav Menu Essentials */
.nav-menu {
   
    margin: 0;
    padding: 0;
    list-style: none;
  
    
  ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 99;
  }
    
  li {
    position: relative;
    white-space: nowrap;
  }
    
  > li {
    float: left;
  }
    
  li:hover > ul,
  li.sfHover > ul {
    display: block;
  }
    
  ul ul {
    top: 0;
    left: 100%;
  }
    
  ul li {
    min-width: 180px;
  }
}

/* Nav Menu Arrows */
.sf-arrows {
  .sf-with-ul {
    padding-right: 30px;
  }
    
  .sf-with-ul:after {
    content: "\f107";
    position: absolute;
    right: 15px;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
  }
    
  ul .sf-with-ul:after {
    content: "\f105";
  }
}

/* Nav Meu Container */
#nav-menu-container {
  margin: 0;
  @media (max-width: 768px) {
    display: none;
  }
}

/* Nav Meu Styling */
.nav-menu {
  a {
    padding: 22px 15px 18px 15px;
    text-decoration: none;
    display: inline-block;
    color: #fff;
    font-family: $font-secondary;
    font-weight: 300;   
    font-size: 20px;
    outline: none;
  }
    
  a:hover, li:hover > a, .menu-active > a  {
    color: rgba(255, 255, 255, .75);
  }
    
  ul {
    margin: 4px 0 0 15px;
    box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.08);
      
    li {
      background: #fff;
      border-top:  1px solid #f4f4f4;
      &:first-child {
        border-top: 0;
      }
      
      &:hover {
        background: $color-primary;
        transition: 0.3s;
      }
      a {
        color: #333;
        transition: none;
        padding: 10px 15px;
        &:hover {
          color: #fff;
        }

      }

    }
    
    ul {
      margin: 0;
    }
  }
}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  margin: 8px 10px 0 0;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
  
  i {color: #fff; }
    
  @media (max-width: 768px) {
    display: inline; 
  }
    
}

/* Mobile Nav Styling */
#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: $color-secondary;
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
    
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    
    li {
      position: relative;
      a {
        color: #fff;
        font-size: 16px;
        overflow: hidden;
        padding: 10px 22px 10px 15px;
        position: relative;
        text-decoration: none;
        width: 100%;
        display: block;
        outline: none;
        &:hover {
          color: #fff;
        }
      }
      
      li {
        padding-left: 30px;
      }
    }
    
    .menu-has-children i {
      position: absolute;
      right: 0;
      z-index: 99;
      padding: 15px;
      cursor: pointer;
      color: #fff;
      &.fa-chevron-up {
        color: $color-primary;
      }
    }
    
    .menu-item-active {
      color: $color-primary;
    }
  }
}

#mobile-body-overly {
  width:100%; 
  height:100%; 
  z-index:997;
  top:0; 
  left:0; 
  position:fixed; 
  background: rgba(0, 0, 0, 0.6); 
  display: none;
}

/* Mobile Nav body classes */
body {
  &.mobile-nav-active {
    overflow: hidden;
    
    #mobile-nav {
      left: 0;
    }
    
    #mobile-nav-toggle {
      color: #fff;
    }
  }
}

/* ==================================================
  Stats Styling
================================================== */

.stats-row {
    margin-top: 65px;
}
.stats-col {
    .circle {
        display: inline-block;
        width: 160px;
        height: 160px;
        font-family: $font-primary;
        font-weight: 500;
        color: #666;
        border: 6px solid $color-primary;
        border-radius: 50%;
        padding: 55px 25px 0 25px;
        position: relative;
        .stats-no {
            color: #fff;
            width: 70px;
            height: 70px;
            line-height: 70px;
            top: -25px;
            right: -15px;
            font-family: $font-primary;
            font-size: 18px;
            background-color: $color-secondary;
            position: absolute;
            border-radius: 50%;
            font-weight: 700;
        }
    }
}
/* ==================================================
  Parallax Styling
  ================================================== */

.block {
    color: #fff;
    height: 490px;
    overflow: hidden;
    padding-top: 85px;
    h2 {
        text-transform: uppercase;
        margin-bottom: 15px;
    }
    p {
        color: #fff;
        margin-bottom: 45px;
    }
}
/* ==================================================
  Features Styling
  ================================================== */

.features {
    h2 {
        color: $color-primary;
    }
    padding-bottom: 45px;
}
.feature-col {
    display: table;
    padding-bottom: 45px;
    > div > div {
        display: table-cell;
        vertical-align: middle;
        &:last-child {
            padding-left: 20px;
        }
    }
    .feature-icon {
        width: 70px;
        height: 70px;
        margin-bottom: 15px;
        // background: $color-primary;

        background: rgb(0,125,171);
background: linear-gradient(129deg, rgba(0,125,171,1) 39%, rgba(12,72,112,1) 100%);

        color: #fff;
        font-size: 24px;
        text-align: center;
        line-height: 70px;
        border-radius: 50%;
        border: 2px solid  $color-primary;
        display: inline-block;
    }
    h3 {
        color: $color-primary;
    }
    p {
        font-family: $font-secondary;
        font-size: 14px;
        color: #999;
    }
}
/* ==================================================
  Call-to-action Styling
  ================================================== */

.cta {
    // background-color: $color-primary;
    background: rgb(0,125,171);
background: linear-gradient(90deg, rgba(0,125,171,1) 39%, rgba(12,72,112,1) 100%);
    padding: 5rem 0;
    h2 {
        margin-bottom: 5px;
    }
    h2,
    p {
        color: #fff;
    }
    p {
        margin-bottom: 0;
        opacity: .75;
    }
    .btn-ghost {
        position: relative;
        top: 13px;
    }
}
/* ==================================================
  Portfolio Styling
  ================================================== */

.portfolio {
  padding-top: 0;
    background-color: #edf6ff;
    padding-bottom: 0;
    h2 {
        color: $color-primary;
        margin-bottom: 25px;
    }
}
.portfolio-grid {
    // margin-top: 65px;
    .row {
        margin: 0;
    }
    .row > div {
        padding: 0;
        .card {
            img {
                width: 100%;
            }
            .portfolio-over {
                position: absolute;
                display: table-cell;
                vertical-align: middle;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, .8);
                top: 0;
                left: 0;
                text-align: center;
                opacity: 0;
                visibility: hidden;
                @include transition(all .3s ease);
                > div {
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .card-title {
                    color: #fff;
                    font-size: $font-size-large;
                }
                .card-text {
                    color: #fff;
                    opacity: .75;
                    padding: 0 45px;
                }
            }
            &:hover .portfolio-over {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
/* ==================================================
  Team Styling
  ================================================== */

.team {
    h2 {
        color: $color-primary;
    }
    // .col-sm-3 {padding: 0; }
    .card {
        > a {
            display: block;
        }
        img {
            width: 100%;
        }
        h4 {
            color: #fff;
            text-transform: uppercase;
        }
        p {
            font-size: 11px;
            color: #fff;
            opacity: .75;
            margin: 0;
            padding: 0 35px;
        }
        .social-nav {
            margin-bottom: 45px;
            a {
                color: #fff;
                font-size: 16px;
                margin: 0 4px;
                &:hover {
                    opacity: .75;
                }
            }
        }
        &:hover {
            .team-over {
                opacity: 1;
                visibility: visible;
            }
            .card-title-wrap {
                background-color: $color-primary;
                .card-title, .card-text {
                    color: #fff;
                }
                a {color: white;}
            }
        }
    }
    .team-over {
        padding-top: 45px;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .8);
        top: 0;
        left: 0;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        @include transition(all .3s ease);
    }
    .card-title-wrap {
        padding: 15px 25px;
        position: relative;
        z-index: 9;
        background-color: #fff;
        .card-title, .card-text {
            font-family: $font-primary;
            display: block;
            margin: 0;
        }
        .card-title {
            font-size: $font-size-sm-medium;
            color: #333;
        }
        .card-text {
            font-size: $font-size-small;
            color: #999;
        }
        a {color: $color-primary;}
    }
}

/* ==================================================
  Contact Section
  ================================================== */
#contact {
  // background: $color-primary;
  background: rgb(0,125,171);
background: linear-gradient(0deg, rgba(0,125,171,1) 39%, rgba(12,72,112,1) 100%);
  padding: 140px 0 260px 0;
  
  h2 {
    font-family: $font-primary;
    color: white;
  }

  .info {

    i {
      font-size: 32px;
      color: white;
      float: left;
    }
    
    p {
      padding: 0 0 10px 50px;
      line-height: 24px;
      color: white;
      a {
        color: #ffb90c;
        font-size: 1.5em;
        &:hover {color:white;}
      }
    }
    
  }
  
  .form {
    
    #sendmessage {
      color: white;
      border: 1px solid white;
      display:none;
      text-align:center;
      padding:15px;
      font-weight:600;
      margin-bottom:15px;
    }

    #errormessage {
      color: red;
      display:none;
      border: 1px solid red;
      text-align:center;
      padding:15px;
      font-weight:600;
      margin-bottom:15px;
    }

    #sendmessage.show, #errormessage.show, .show {
      display:block;
    }
    
    .validation {
      color: red;
      display:none;
      margin: 0 0 20px;
      font-weight:400;
      font-size:13px;
    }
    
    input, textarea {
      border-radius: 0;
      box-shadow: none;

    }
    
    button[type="submit"] {
      // background: $color-primary;
      border: 0;
      padding: 10px 24px;
      // color: #fff;
      transition: 0.4s;
      &:hover {
        background: lighten($color-primary, 10%);
      }

    }

  }
}

/* ==================================================
  Footer Styling
  ================================================== */

.site-footer {
    background-color: #111;
    padding:0;
    h2, p {
        color: #fff;
    }
    p {
        opacity: .75;
        line-height: 1.35*$line-height;
    }
    h2,
    .btn {
        margin-bottom: 25px;
    }
    .social-nav {
        a {
            color: #fff;
            opacity: .25;
            &:hover {
                opacity: 1;
            }
        }
    }
    .bottom {
        background-color: #000;
        padding: 20px 0;
        .list-inline, p {
            margin: 0;
        }
        .list-inline {
            position: relative;
            top: 5px;
            a {
                color: #fff;
                font-family: $font-secondary;
                margin-right: 15px;
                &:hover {
                    color: $color-primary;
                }
            }
        }
    }
    
    .credits {
      color: #ddd;
      a {
        color: $color-primary;
      }

    }

}
/* ==================================================
  Responsive Styling
  ================================================== */

@media (max-width: 61.9em) {
    section,
    .block,
    // .cta {padding: 35px 0; }
    .features {
        padding-bottom: 5px;
    }
    p,
    .block p {
        margin: 0;
    }
    .hero-brand {
        margin-bottom: 35px;
    }
    .tagline {
        margin: 35px 0;
    }
    h1 {
        font-size: 32px;
        margin: 0;
    }
    h2,
    .tagline {
        font-size: 24px;
    }
    h2 {
        margin-bottom: 25px;
    }
    h3 {
        font-size: 14px;
    }
    .hero {
        padding: 75px 0;
    }
    .stats-col {
        margin-bottom: 25px;
    }
    .block {
        height: auto;
    }
    .feature-col {
        padding-bottom: 30px;
    }
    .portfolio-grid {
        .card {
            h3.card-title {
                font-size: $font-size-small !important;
            }
            .card-text {
                font-size: 13px;
            }
        }
    }
    .team .team-over {
        padding-top: 20px;
    }
    .team .card .social-nav {
        margin-bottom: 15px;
        padding: 0;
    }
    .site-footer {
        .social-nav {
            margin-bottom: 35px;
        }
        .list-inline {
            text-align: center;
            padding-bottom: 15px;
            li {
                display: inline-block;
            }
        }
    }
}
@media (max-width: 480px) {
  .hero {
    padding-top: 0;
  }
}
@media (max-width: 767px) {
    .block {
        padding-left: 30px;
        padding-right: 30px;
    }
}
